import React from 'react'
import './apoio.css'
import BtnApoio from './parts/btnApoio'

const Apoio = (props) =>{
    console.log(props.programas)
    return(
        <div className="container">
            <div className="row">
                <div className="col-sm-12 ">
                    <div className="apoio boxDevir orienTacaoEApoio">
                        <div className="logosApoio">
                            <ul>
                                {props.programas.map((programa, i) => {
                                    return <BtnApoio programa={programa} abrirFile={props.abrirFile}  disabled={programa.disabled} icon={programa.nome}  alt={programa.alt} key={programa.id} />
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Apoio