import React, { Component } from 'react'
import Layout               from '../components/layout/indexObra'
import Slider               from '../components/sliders/SliderObras'
import Signa                from '../components/signa/signa'
import ContainerWithRow     from '../components/ui/bootstrap/ContainerWithRow'
import Loader               from '../components/ui/loaderView'
import Page                 from '../components/page/Page'
import ControleDeErro       from '../hoc/ControleDeErro'
// ACTIONS: Atualizar o state da aplicação
import { listarDadosObra, leituraSliderConteudo } from '../store/actions/action.obra'
import { connect } from 'react-redux'
class indexObra extends Component {
    state = {
        loading: true,
        show: false,
        showConteudo: false,
        showMobMenu: false,
        atualizarSenha: false
    }
    async componentDidMount() {
        await this.props.pegarDadosObras(" ")
        this.props.pegarLeituraSliderConteudo()
        this.change()      
    }
    change =()=>{
        this.setState({
            loading: false
        })
    }

    f_logOut(){
        console.log(this)
        window.localStorage.setItem("_IDUSER", "")
        this.props.history.push('/login');
    }
    f_modal = (valor) => {
        this.setState({
            show: valor
        })
    }
    f_mobMenu = (value)=>{
        this.setState({
            showMobMenu: value
        })
    }
    f_mobSenha = (value) => {
        this.setState({
            atualizarSenha: value
        })
    }
    f_modal_conteudo = (valor) => {
        if(valor === false){
            this.props.limparConteudoPagina()
        }
        this.setState({
            showConteudo: valor
        })
    }
    f_carregarConteudo(id){
        this.f_modal_conteudo(true)
        this.props.pegarConteudoUnico(id);
        
    }
    render() { 
        console.log(this.props.dadosObra)
        return (
            <ControleDeErro>            
            <Layout {...this.props}>
                <Page className="consolidacaoCultura" titulo="Portal da Cultura">
                {this.state.loading ?                 
                    <Loader /> : 
                    <div className="indexObra">
                        {/* SLIDER */}


                        {/* SIGNA */}
                        <ContainerWithRow fluid={false}>
                            <img src="/images/logos-coloridos/devir.svg" className='img-center img-responsive' style={{maxWidth: 200, margin: "auto", marginTop: 20, opacity: 0.8}}/>
                            <div className="col-sm-12">
                            <p style={{textAlign: "center", maxWidth: 450, margin: "auto", marginTop: 10}}>{this.props.dadosObra.CRM_TEXTO_QUESTAO}</p>
                            </div>
                        </ContainerWithRow>   
                    </div>                    
                }
                </Page>
            </Layout>
            </ControleDeErro>
        )
    }
}

const mapStateToProps = (state) => {
    console.log(state)
    return {
        conteudosObra: state.obra.conteudos,
        noticiasObra: state.obra.conteudos,
        dadosObra: state.obra.dados[0],
        slider: state.obra.leituraSliderConteudo ? state.obra.leituraSliderConteudo[0] : undefined,
    }
}




const mapActionsToDispatch = (dispatch) => {
    return {
        pegarDadosObras: (texto) => dispatch(listarDadosObra(texto)),
        pegarLeituraSliderConteudo: () => dispatch(leituraSliderConteudo())

    }
}

export default connect(mapStateToProps, mapActionsToDispatch)(indexObra)

