import React from 'react'

const BtnApoio = (props)=>{
    console.log(props)
    return(
        <button onClick={()=>props.abrirFile(props.programa.arquivoLink)} className="btn btn-atual"  disabled={props.disabled} target="_blank">{props.programa.CMS_TITULO_ANEXO[0]}</button>

    )
}

export default BtnApoio