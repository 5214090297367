import React, {Component} from 'react'
import Layout from  '../../components/layout/indexObra'
import Apoio from '../../components/orientacaoApoio/orientacaoApoio'
import Page from '../../components/page/Page'
import { connect } from 'react-redux'
import ModalApoio from '../../components/orientacaoApoio/modal'
import Loader from '../../components/ui/loaderView'
import { listarDadosObra, leituraRealizacao } from '../../store/actions/action.obra'
import { pegarAgenda, apagarAgenda } from '../../store/actions/action.apoio'


class OrientacaoApoio extends Component{
    state = {
        loading: true,
        showConteudo: false,
        programas: [
            {
                nome: "1º Encontro",
                icon: "+Academia",
                id: '001',
                alt: "Mais academia",
                link: "https://happybizwebv2.s3.sa-east-1.amazonaws.com/protagonistas/Nossas+recomenda%C3%A7%C3%B5es+-+1%C2%BA+encontro.pdf",
                disabled: false
            },
            {
                nome: "2º Encontro",
                icon: "+Academia",
                id: '002',
                alt: "Mais academia",
                link: "https://happybizwebv2.s3.sa-east-1.amazonaws.com/protagonistas/Nossas+recomenda%C3%A7%C3%B5es+-+2%C2%BA+encontro.pdf",
                disabled: false
            },
            {
                nome: "3º Encontro",
                icon: "+Academia",
                id: '003',
                alt: "Mais academia",
                link: "https://happybiz-web.s3-sa-east-1.amazonaws.com/Metanoia/Protagonistas/encontros/Pr%C3%A1xis+3%C2%BA+encontro+Protagonistas+.pdf",
                disabled: true
            }
        ]
    }
    async componentDidMount(){
        await this.props.dadosObra()    
        await this.props.carregarArquivos()
        this.change()
    }
    change =()=>{
        this.setState({
            loading: false
        })
    }
    abrirFile = (url)=>{
        window.open(url, "_blank") 
    }
    
    controlarModal = (agrupador, id) => {
        this.setState({
            showConteudo: !this.state.showConteudo
        })
        this.props.agenda(agrupador, id);

        if(!this.state.showConteudo)
            this.props.apagar()
    }
    render(){
        console.log(this.props)

        return(
            
            <Layout {...this.props}>

                {this.state.loading ? <Loader /> :

                <Page titulo="Realizações">

                    <Apoio abrirFile={this.abrirFile} click={()=>this.controlarModal()} abrirModal={this.controlarModal} programas={this.props.arquivos} />

                    <ModalApoio modal={this.state.showConteudo} 
                        f_fechar_conteudo={this.controlarModal} 
                        conteudoTrocar={this.conteudoTrocar}
                        modalConteudo={this.props.modalConteudo}
                        />
                        
                </Page>          
                }

            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        dadosObra: state.obra.dados[0],
        modalConteudo: state.apoio.modal,
        arquivos: state.realizacao.arquivos
        
    }
}

const mapActionsToProps = (dispatch) =>{

    return{
        dadosObra: () => dispatch(listarDadosObra()),
        agenda: (agrupador, id) => dispatch(pegarAgenda(agrupador, id)),
        apagar: ()=> dispatch(apagarAgenda()),
        carregarArquivos: ()=> dispatch(leituraRealizacao())
    }
}

export default connect(mapStateToProps,mapActionsToProps)(OrientacaoApoio)

