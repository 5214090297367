import React, {useState, useEffect} from 'react'
import { _API, _EMPRESA } from '../../../config/config'
import axios from 'axios'



const footerMetagram = (props)=>{  
    const [comentarios, setarComentarios] = useState(false)
    const [avisar, setarAvisar] = useState(false)
    const [enviar, setarEnviar] = useState(false)
    useEffect(()=>{
         async function enviarEmail() {

            await axios.post(`${_API}/enviarNotificacao`, {
                cad_empresa: _EMPRESA,
                cad_filial: '0001',
                cms_id_usuario: localStorage.getItem("_IDUSER"),
            })
            .then(response => {
                console.log('test envado')
                setarAvisar(true)
            })    
        }
        //if(avisar)
        //    enviarEmail()
    }, [avisar])

    function confirmarEnviar(e){
        setarEnviar(e)
    }

    function html(props){
        switch(props.tipo){
            case "imagem":
                return(
                    <div className="footer-box">
                        <div className="comentario">
                            <div></div>
                            <p>
                                {props.desc}
                            </p>
                        </div>
                        <div className="links">   
                            {avisar ? <button className="btn btn-defaut" disabled={true}>Turma Avisada</button> : <button className="btn btn-defaut" onClick={()=> confirmarEnviar(true)} style={{display: enviar ? "none" : "block"}}>Avisar participantes</button> }
                        </div>  
                        <div className="confirmar-envio text-left" style={{display: enviar ? "block" : "none"}}>
                            <p>Deseja avisar os participantes?</p>
                            <div className="botoes-confirmar">
                                <button className="btn btn-defaut" onClick={()=> {setarAvisar(!avisar)
                                                                                  confirmarEnviar(false)}}>
                                Sim</button>
                                <button className="btn btn-defaut" onClick={()=> confirmarEnviar(false)}>Não</button>
                            </div>
                        </div>                     
                    </div>
                )
            
            default :
                return(
                    <div className="footer-box">
                        <div className={`comentario ${props.comentarios ? 'comentario-ativo' : ' '}`}>
                            <div className="fade-comentario" style={{backgroundColor:`${props.comentarios ? 'transparent' : ' '}`}}></div>                            <p>
                                {props.tipo === "texto" ? "" : props.desc}
                            </p>
                        </div>
                        <div className="links">   
                            {avisar ? <button className="btn btn-defaut" disabled={true}>Turma Avisada</button> : <button className="btn btn-defaut" onClick={()=> confirmarEnviar(true)} style={{display: enviar ? "none" : "block"}}>Avisar participantes</button> }
                        </div>  
                        <div className="confirmar-envio text-left" style={{display: enviar ? "block" : "none"}}>
                            <p>Deseja avisar a turma?</p>
                            <div className="botoes-confirmar">
                                <button className="btn btn-defaut" onClick={()=> {setarAvisar(!avisar)
                                                                                  confirmarEnviar(false)}}>
                                Sim</button>
                                <button className="btn btn-defaut" onClick={()=> confirmarEnviar(false)}>Não</button>
                            </div>
                        </div>  
                    </div>
                );
        }  
    }  

    return html(props)    
    
    
}

export default footerMetagram